<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card :loading="loading" v-if="!courtesyEmitters">
        <v-card-text class="text-center">
          <img
            v-if="$vuetify.theme.dark"
            class="mb-4"
            height="50"
            src="@/assets/images/logo/logo-light.png"
            alt=""
          />
          <img
            v-else
            class="mb-4"
            height="50"
            src="@/assets/images/logo/logo-dark.png"
            alt=""
          />
          <template v-if="loading && !error">
            <v-alert
              border="left"
              class="text-left"
              text
              icon="mdi-information-outline"
              type="info"
            >
              Por favor, aguarde um momento enquanto buscamos os ingressos
              disponíveis.
            </v-alert>
          </template>
          <template v-else>
            <v-alert
              border="left"
              class="text-left"
              text
              icon="mdi-alert-circle-outline"
              type="error"
            >
              {{ error }}
            </v-alert>
            <v-btn
              color="secondary"
              class="mt-4"
              block
              :to="{
                name: 'sign-in',
              }"
            >
              Voltar
            </v-btn>
          </template>
        </v-card-text>
      </base-card>
      <div v-else>
        <v-card class="rounded-lg">
          <v-card-text>
            <h5 class="text-center">Emitir Cortesias</h5>

            <v-card
              elevation="0"
              class="d-flex gap-2 align-center"
              :to="`/shop/${party.Organization.slug}/${party.slug}`"
              target="_blank"
            >
              <div>
                <v-img
                  :src="party.cover"
                  height="60"
                  :width="(60 * 16) / 9"
                  :aspect-ratio="16 / 9"
                  class="rounded-lg"
                />
              </div>
              <div style="display: grid" class="flex-grow-1">
                <h5 class="mb-0 text-truncate">
                  {{ party.name }}
                </h5>
                <span class="mb-0">{{ party.date | date }}</span>
              </div>
              <v-icon>mdi-chevron-right</v-icon>
            </v-card>

            <div class="d-flex flex-column gap-2 mt-4">
              <v-card
                v-for="courtesyEmitter in courtesyEmitters"
                :key="courtesyEmitter.id"
                class="pa-3"
                outlined
              >
                <div class="d-flex gap-1 justify-space-between">
                  <h6 class="mb-0">
                    {{ courtesyEmitter.TicketBlock.TicketGroup.name }} •
                    {{ courtesyEmitter.TicketBlock.name }}
                  </h6>
                  <v-chip
                    v-if="courtesyEmitter.quantity > 0"
                    label
                    small
                    class="font-weight-bold px-2 text-14"
                  >
                    {{ courtesyEmitter.Tickets.length }}/{{
                      courtesyEmitter.quantity
                    }}
                  </v-chip>
                </div>
                <p
                  v-if="courtesyEmitter.Tickets.length == 0"
                  class="mt-4 lh-1 text-center"
                >
                  Nenhum ingresso emitido
                </p>
                <v-simple-table v-else dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Contemplado</th>
                        <th class="text-left">Data</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="ticket in courtesyEmitter.Tickets"
                        :key="ticket.id"
                      >
                        <td class="text-left">
                          {{ ticket.Owner?.name || "Conta não criada" }}
                        </td>
                        <td class="text-left">
                          {{ ticket.createdAt | date("DD/MM/YYYY HH:mm") }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-btn
                  v-if="
                    courtesyEmitter.Tickets.length < courtesyEmitter.quantity
                  "
                  block
                  color="primary"
                  small
                  depressed
                  class="mt-4"
                  @click="emitCourtesy(courtesyEmitter.TicketBlock)"
                >
                  Emitir ingresso
                </v-btn>
                <v-btn
                  v-if="
                    this.courtesyEmitter.Tickets.length -
                      this.courtesyEmitter.quantity >
                    1
                  "
                  block
                  color="primary"
                  small
                  class="mt-1"
                  text
                  depressed
                  @click="emitMultipleCourtesy(courtesyEmitter)"
                >
                  Emitir em massa
                </v-btn>
              </v-card>
            </div>
          </v-card-text>
        </v-card>
        <v-btn block href="/" text class="mt-2" color="white">
          ir para o app
        </v-btn>
      </div>
    </div>
    <courtesy-emitter-modal
      v-if="party"
      @refresh="getCourtesyEmitter"
      :party="party"
    />

    <courtesy-multiple-emitter-modal
      v-if="party"
      @refresh="getCourtesyEmitter"
      :party="party"
    />
  </div>
</template>
<script>
import COURTESY_EMITTER from "@/services/staff/courtesyEmitter";
import CourtesyEmitterModal from "@/components/staff/courtesyEmitter/CourtesyEmitterModal.vue";
import CourtesyMultipleEmitterModal from "@/components/staff/courtesyEmitter/CourtesyMultipleModal.vue";

export default {
  components: { CourtesyEmitterModal, CourtesyMultipleEmitterModal },
  name: "CourtesyEmitterResponsible",
  metaInfo: {
    title: "Emitir ingressos",
  },
  data() {
    return {
      show: false,
      loading: true,
      error: false,
      innerLoading: false,
      innerError: false,
      courtesyEmitters: null,
      party: null,
    };
  },
  methods: {
    async getCourtesyEmitter() {
      try {
        let id = this.$route.params.partyId;
        let { courtesyEmitters, party } = await COURTESY_EMITTER.get(id);

        this.party = party;
        this.courtesyEmitters = courtesyEmitters;
        this.loading = false;
        this.innerLoading = false;
      } catch (e) {
        this.loading = false;
        this.error = e.message;
      }
    },
    emitCourtesy(tb) {
      this.$emit("courtesy-emitter", tb);
    },
    emitMultipleCourtesy(tb) {
      this.$emit("courtesy-emitter-multiple", tb);
    },
  },
  mounted() {
    let id = this.$route.params.partyId;
    if (!id) {
      this.loading = false;
      this.error =
        "O código de sangria não foi informado, por favor, informe o código e tente novamente.";
    }
    this.getCourtesyEmitter();
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background: radial-gradient(
      circle at 50% 50%,
      rgba(0, 0, 0, 50%) 0%,
      rgba(0, 0, 0, 90%) 90vw
    ),
    url(/img/logo/auth-background.png), black;
  display: flex;
  background-size: cover;
  background-position: center;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}
</style>
